.App {
  text-align: center;
  width: 100%;
  position: relative;
  min-height: 95vh;
}
/* .content {
  min-height: 86vh;
} */
.App{
  width: 100%;
}
.content-wrap {
  padding-bottom: 2.5rem;
  width: 100%;
}
.pagetitle {
  clear: both !important;
  text-align: center;
  margin: 1% auto;
  width: 70%;
  border-bottom: 5px double #e5e5e5;
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .pagetitle {
    width: 50%;
  }
}

/*  Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {

} */

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pagetitle {
    width: 30%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .pagetitle {
    width: 25%;
  }
}
