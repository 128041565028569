.footer {
  clear: both !important;
  padding: 15px;
  color: #200f08;
  background-color: var(--second-color);
  /* background-color: #e5e5e5; */
  text-align: left;
  margin: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3rem;
  font-size: 0.9em;
}

/* footer.container {
  max-width: 100%;
  bottom: 0;
} */
