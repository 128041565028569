@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans+SC|Roboto+Slab|Ubuntu");

:root {
  --background-primary-color: #f8f9fa;
  --link-primary-color: #3f72af;
  --primary-text-color: #212121; 
  --secondary-text-color: #757575; 
  --first-color: #f9f7f7; 
  --second-color: #dbe2ef; 
  /* --third-color: #3f72af;  */
  --fourth-color: #112d4e; 
  --divider-color: #BDBDBD; 
  --icon-color: #000000; 
}

html, body {
    height: 100%;
}

body {
  background-color: var(--first-color);
  color: var(--primary-text-color);
  font-family: Verdana, Tahoma, Arial, sans-serif;
  font-size: 1.1em;
  margin: 0 auto;
}

a {
  color: var(--link-primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--fourth-color);
  font-weight: 600;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
}
.container p {
  text-align: justify;
}

.conentParagraph{
  margin: 0 3%;
  text-align: left;
}
