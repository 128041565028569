.navbar{
  background-color: var(--second-color);
}

.navbar-collapse{
  text-align: left !important;
}
button.navbar-toggler {
  margin-right: 3%;
}

.nav-link {
  color: var(--fourth-color) !important;
  font-size: 1.2rem;
}
a.nav-link {
  color: #fff;
  font-weight: bold;
  padding: 8px 5px;
  text-transform: uppercase;
  text-decoration: none;
  /* position: relative; */
  margin: 5px 15px;
}

.dropdown-item:hover{
  width: 90% 
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .dropdown-item:hover{
  width: 95% 
}
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  a.navbar-brand {
    font-size: 1.1rem;
  }
  .nav-link {
    margin-bottom: 1%;
    font-size: 0.9rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .dropdown-item:hover{
  width: 85% 
}
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  a.navbar-brand {
    font-size: 1.2rem;
  }
  .nav-link {
    margin-top: 1%;
    font-size: 1rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .nav-link {
    font-size: 1.2rem;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

}
