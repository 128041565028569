@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC|Roboto+Slab|Ubuntu);
:root {
  --background-primary-color: #f8f9fa;
  --link-primary-color: #3f72af;
  --primary-text-color: #212121; 
  --secondary-text-color: #757575; 
  --first-color: #f9f7f7; 
  --second-color: #dbe2ef; 
  /* --third-color: #3f72af;  */
  --fourth-color: #112d4e; 
  --divider-color: #BDBDBD; 
  --icon-color: #000000; 
}

html, body {
    height: 100%;
}

body {
  background-color: #f9f7f7;
  background-color: var(--first-color);
  color: #212121;
  color: var(--primary-text-color);
  font-family: Verdana, Tahoma, Arial, sans-serif;
  font-size: 1.1em;
  margin: 0 auto;
}

a {
  color: #3f72af;
  color: var(--link-primary-color);
  text-decoration: none;
}

a:hover {
  color: #112d4e;
  color: var(--fourth-color);
  font-weight: 600;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
}
.container p {
  text-align: justify;
}

.conentParagraph{
  margin: 0 3%;
  text-align: left;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  min-height: 95vh;
}
/* .content {
  min-height: 86vh;
} */
.App{
  width: 100%;
}
.content-wrap {
  padding-bottom: 2.5rem;
  width: 100%;
}
.pagetitle {
  clear: both !important;
  text-align: center;
  margin: 1% auto;
  width: 70%;
  border-bottom: 5px double #e5e5e5;
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .pagetitle {
    width: 50%;
  }
}

/*  Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) {

} */

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .pagetitle {
    width: 30%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .pagetitle {
    width: 25%;
  }
}

.navbar{
  background-color: var(--second-color);
}

.navbar-collapse{
  text-align: left !important;
}
button.navbar-toggler {
  margin-right: 3%;
}

.nav-link {
  color: var(--fourth-color) !important;
  font-size: 1.2rem;
}
a.nav-link {
  color: #fff;
  font-weight: bold;
  padding: 8px 5px;
  text-transform: uppercase;
  text-decoration: none;
  /* position: relative; */
  margin: 5px 15px;
}

.dropdown-item:hover{
  width: 90% 
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .dropdown-item:hover{
  width: 95% 
}
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  a.navbar-brand {
    font-size: 1.1rem;
  }
  .nav-link {
    margin-bottom: 1%;
    font-size: 0.9rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .dropdown-item:hover{
  width: 85% 
}
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  a.navbar-brand {
    font-size: 1.2rem;
  }
  .nav-link {
    margin-top: 1%;
    font-size: 1rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .nav-link {
    font-size: 1.2rem;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

}

.footer {
  clear: both !important;
  padding: 15px;
  color: #200f08;
  background-color: var(--second-color);
  /* background-color: #e5e5e5; */
  text-align: left;
  margin: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3rem;
  font-size: 0.9em;
}

/* footer.container {
  max-width: 100%;
  bottom: 0;
} */

